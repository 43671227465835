import React from "react";
import { Link } from "gatsby-plugin-intl";
import { concatClassNames as cn } from "@sys42/utils";
import {
  ButtonPrimary,
  Tooltip,
  addUtmParams,
  urlBookADemo,
  advertedPoolSize,
} from "@userbrain/website-ui";

import * as styles from "./styles.module.css";

import { ToggleBillingCycle } from "./toggleBillingCycle";
import { PayAsYouGo } from "./PayAsYouGo";

import { getCreateAccountUrl, usePrices } from "../../helpers";

import imgStarter from "./starter.png";
import imgPro from "./pro.png";
import imgAgency from "./agency.png";
import imageBulletCancelAnytime from "./bulletFreeTrial.png";
import imageBulletOk from "./bulletOk.png";
import imageBulletLifebuoy from "./bulletLifebuoy.png";

const IncludedTestersLiFeature = ({ billingCycle, testersPerMonth }) => {
  if (billingCycle === "annual") {
    return (
      <li>
        <strong>{testersPerMonth * 12} testers included</strong> each{" "}
        <span style={{ whiteSpace: "nowrap" }}>
          year{" "}
          <Tooltip>
            You get {testersPerMonth * 12}&nbsp;testers each year from our{" "}
            {advertedPoolSize}&nbsp;pool of qualified testers. All testers are
            available upfront. Unused testers roll over until you cancel.
          </Tooltip>
        </span>
      </li>
    );
  } else {
    return (
      <li>
        {testersPerMonth} testers included each{" "}
        <span style={{ whiteSpace: "nowrap" }}>
          month{" "}
          <Tooltip>
            You get {testersPerMonth}&nbsp;testers each month from our{" "}
            {advertedPoolSize}&nbsp;pool of qualified testers. Unused testers
            roll over until you cancel.
          </Tooltip>
        </span>
      </li>
    );
  }
};

export function PricingFull2022(props) {
  const {
    className,
    currency,
    currencySymbol,
    billingCycle,
    onChangeBillingCycle,
    ...containerProps
  } = props;

  const showAnnualPrice = billingCycle === "annual";
  const periodLabel = showAnnualPrice ? "/ mo. billed yearly" : "per month";

  const {
    priceProYearly,
    priceProMonthly,
    priceStarterYearly,
    priceStarterMonthly,
    priceAgencyYearly,
    priceAgencyMonthly,
  } = usePrices();

  return (
    <div className={cn(className, styles.pricingFull)} {...containerProps}>
      <ToggleBillingCycle
        className={styles.toggleBillingCycle}
        onChange={onChangeBillingCycle}
        value={billingCycle}
      />

      <div className={styles.plans}>
        <Plan
          name="Starter"
          imageSrc={imgStarter}
          imageAlt="Hand pressing a buzzer"
          currencySymbol={currencySymbol}
          price={showAnnualPrice ? priceStarterYearly : priceStarterMonthly}
          periodLabel={periodLabel}
          buttonHref={getCreateAccountUrl("starter")}
          billingCycle={billingCycle}
          testersPerMonth={3}
          admins={1}
          collaborators={5}
        />

        <Plan
          name="Pro"
          isMostPopular
          imageSrc={imgPro}
          imageAlt="A spaceshuttle on turquoise background"
          currencySymbol={currencySymbol}
          price={showAnnualPrice ? priceProYearly : priceProMonthly}
          periodLabel={periodLabel}
          buttonHref={getCreateAccountUrl("pro")}
          billingCycle={billingCycle}
          testersPerMonth={10}
          admins={3}
          collaborators={20}
        />

        <Plan
          name="Agency"
          imageSrc={imgAgency}
          imageAlt="Old Apple computer with a pixalated open mail envelope."
          currencySymbol={currencySymbol}
          price={showAnnualPrice ? priceAgencyYearly : priceAgencyMonthly}
          periodLabel={periodLabel}
          buttonHref={getCreateAccountUrl("agency")}
          billingCycle={billingCycle}
          testersPerMonth={30}
          admins={20}
          collaborators={50}
        />
      </div>

      <div className={styles.bullets}>
        <Bullet
          imgSrc={imageBulletOk}
          imgAlt="Line drawing of a hand making an OK sign"
          title="100% satisfaction guarantee"
          description="If you're not happy with one of our testers, you'll get a new one at no extra cost."
        />
        <Bullet
          imgSrc={imageBulletCancelAnytime}
          imgAlt="Line drawing of a mouse pointer clicking"
          title="Change or cancel anytime"
          description={
            "Upgrade, downgrade, or cancel anytime. Changes will be effective at the end of your billing cycle."
          }
        />
        <Bullet
          imgSrc={imageBulletLifebuoy}
          imgAlt="Line drawing of a lifebuoy"
          title="Still got questions?"
          description={
            <>
              You can{" "}
              <Link className={styles.fancyLink} to={"/contact"}>
                contact us
              </Link>{" "}
              or{" "}
              <a
                className={styles.fancyLink}
                href={addUtmParams(urlBookADemo, "website", "pricing")}
              >
                book a demo
              </a>
              <br />
              &nbsp;
            </>
          }
        />
      </div>

      <PayAsYouGo
        className={styles.payAsYouGo}
        currencySymbol={currencySymbol}
      />

      <div className={styles.lookingForEnterprise}>
        <div>
          Need more testers, bigger teams, and flexible payment options?
        </div>
        <Link to={"/contact"}>Contact sales for Enterprise solutions</Link>
      </div>
    </div>
  );
}

function Bullet({ imgSrc, imgAlt, title, description }) {
  return (
    <div className={styles.bulletsItem}>
      <img src={imgSrc} alt={imgAlt} />
      <div>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
}

function Plan({
  name,
  imageSrc,
  imageAlt,
  currencySymbol,
  price,
  periodLabel,
  buttonHref,
  billingCycle,
  testersPerMonth,
  collaborators,
  admins,
  isMostPopular,
}) {
  return (
    <div className={styles.plan}>
      <div className={styles.planHeader}>
        <img src={imageSrc} alt={imageAlt} />
        <div>
          <div className={styles.planTitleAndMostPopular}>
            <h2 className={styles.planTitle}>{name}</h2>
            {isMostPopular && (
              <strong className={styles.mostPopular}>Most popular</strong>
            )}
          </div>
        </div>
      </div>
      <div className={styles.planPrice}>
        <span className={styles.planPricePrice}>
          {currencySymbol}
          {price}
        </span>
        <span className={styles.planPricePeriod}>{periodLabel}</span>
      </div>
      <ButtonPrimary
        fullWidth
        className={styles.startFreeTrialButton}
        customTag={"a"}
        href={buttonHref}
      >
        Start your free trial
      </ButtonPrimary>
      <div className={styles.noCreditCardRequired}>
        No credit card required.
      </div>
      <Features
        billingCycle={billingCycle}
        testersPerMonth={testersPerMonth}
        collaborators={collaborators}
        admins={admins}
      />
    </div>
  );
}

function Features({ billingCycle, testersPerMonth, collaborators, admins }) {
  return (
    <ul
      className={cn(
        styles.featureList,
        styles.featureList_green,
        styles.mainFeatures
      )}
    >
      <IncludedTestersLiFeature
        billingCycle={billingCycle}
        testersPerMonth={testersPerMonth}
      />
      <li>
        AI Insights and time-stamped notes{" "}
        <Tooltip>
          Get instant AI insights to reveal key results from your user tests,
          based on AI-enhanced transcripts and time-stamped annotations.
        </Tooltip>
      </li>
      <li>
        Automated test reports{" "}
        <Tooltip>
          Share a comprehensive summary of your tasks, questions, and notes with
          your team in a flash.
        </Tooltip>
      </li>
      <li>
        Create and share highlight clips{" "}
        <Tooltip>
          Create short video clips of your key findings and share them with your
          team and stakeholders.
        </Tooltip>
      </li>
      <li>
        Test with your own users{" "}
        <Tooltip>
          Get up to 100 test sessions with your own customers, users, or team
          members every month. Refills monthly.
        </Tooltip>
      </li>
      <li>
        {collaborators} collaborator seats{" "}
        <Tooltip>
          Collaborators can create tests and order testers. They can also make
          notes, create clips, and share test results.
        </Tooltip>
      </li>
    </ul>
  );
}
