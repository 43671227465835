import React from "react";
// import { } from '../../system42'
import * as styles from "./styles.module.css";
import { Tooltip } from "@userbrain/website-ui";
import { NewBadge } from "../NewBadge";

const CONTENT_TYPE_YES = 0;
const CONTENT_TYPE_NO = 1;
const CONTENT_TYPE_CUSTOM_TEXT = 2;

export function CompareTableRow(props) {
  const {
    addition,
    title,
    tooltip,
    thId,
    categoryThId,
    values,
    activeColumnMobile,
    isNew,
    ...containerProps
  } = props;

  function contentTypeFromValue(value) {
    switch (value) {
      case "yes":
        return CONTENT_TYPE_YES;
      case "no":
        return CONTENT_TYPE_NO;
      default:
        return CONTENT_TYPE_CUSTOM_TEXT;
    }
  }

  function contentFromValue(value) {
    switch (contentTypeFromValue(value)) {
      case CONTENT_TYPE_YES:
        return <span className={styles.yes}>yes</span>;
      case CONTENT_TYPE_NO:
        return <span className={styles.no}>no</span>;
      case CONTENT_TYPE_CUSTOM_TEXT:
      default:
        return <span className={styles.customText}>{value}</span>;
    }
  }

  let activeColumnIndexMobile;

  switch (activeColumnMobile) {
    case "starter":
      activeColumnIndexMobile = 1;
      break;
    case "pro":
      activeColumnIndexMobile = 2;
      break;
    case "agency":
      activeColumnIndexMobile = 3;
      break;
    case "enterprise":
      activeColumnIndexMobile = 3;
      break;
    case "payg":
    default:
      activeColumnIndexMobile = 0;
      break;
  }

  return (
    <tr {...containerProps}>
      <th headers={categoryThId} id={thId} scope={"row"}>
        <span>
          {title}
          {addition && <span className={styles.addition}> {addition}</span>}
          {isNew && (
            <>
              {" "}
              <NewBadge />
            </>
          )}
        </span>
        {tooltip && (
          <Tooltip className={styles.tooltipHeader}>{tooltip}</Tooltip>
        )}
        {contentTypeFromValue(values[activeColumnIndexMobile]) ===
          CONTENT_TYPE_CUSTOM_TEXT && (
          <div className={styles.additionalInfoMobile}>
            {values[activeColumnIndexMobile]}
          </div>
        )}
      </th>
      <td headers={`${categoryThId} ${thId} thPayg`}>
        {contentFromValue(values[0])}
      </td>
      <td headers={`${categoryThId} ${thId} thPro`}>
        {contentFromValue(values[1])}
      </td>
      <td headers={`${categoryThId} ${thId} thAgency`}>
        {contentFromValue(values[2])}
      </td>
      <td headers={`${categoryThId} ${thId} thEnterprise`}>
        {contentFromValue(values[3])}
      </td>
    </tr>
  );
}
