import React from "react";
import { concatClassNames as cn } from "@sys42/utils";
// import { } from '../../system42'
import * as styles from "./styles.module.css";
import { Button, Tooltip } from "@userbrain/website-ui";
import { getCreateAccountUrl, usePrices } from "../../../helpers";
import imgPayAsYouGo from "../payAsYouGo.png";

export function PayAsYouGo(props) {
  const { className, currencySymbol, ...containerProps } = props;

  const { pricePayAsYouGoTest } = usePrices();

  return (
    <div className={cn(className, styles.payAsYouGo)} {...containerProps}>
      <div className={styles.cell}>
        <img
          className={styles.planLogo}
          src={imgPayAsYouGo}
          alt="Pixalated pointing hand"
        />
        <h2>Pay as you go</h2>
        <div className={styles.price}>
          <span className={styles.pricePrice}>
            {currencySymbol}
            {pricePayAsYouGoTest}
          </span>
          <span className={styles.pricePeriod}>per tester</span>
        </div>
      </div>
      <div className={styles.cell}>
        <ul className={cn(styles.featureList, styles.featureList_green)}>
          <li>
            Pay-per-use{" "}
            <span style={{ whiteSpace: "nowrap" }}>
              — no minimum order{" "}
              <Tooltip>
                Whether you need 1 tester or 1,000 —&nbsp;just buy Userbrain
                testers on demand. No&nbsp;subscription&nbsp;needed.
              </Tooltip>
            </span>
          </li>
          <li>
            No subscription needed{" "}
            <Tooltip>
              Save money and buy credits in advance. 1&nbsp;credit =
              1&nbsp;Userbrain tester. Credits don’t expire.
            </Tooltip>
          </li>
        </ul>
      </div>
      <div className={styles.cell}>
        <Button
          className={styles.startButton}
          customTag={"a"}
          href={getCreateAccountUrl("payg")}
          fullWidth
        >
          Create Account
        </Button>
      </div>
    </div>
  );
}
